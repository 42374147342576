import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "program-list" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "HeaderMainPage__breadcrumb-link-text" }
const _hoisted_4 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['mobile','program-list-current-mode', 'program-list-current-mode--error'])
}
const _hoisted_5 = { class: "inner-sm-min" }
const _hoisted_6 = { class: "page2Col" }
const _hoisted_7 = { class: "page2Col__main" }
const _hoisted_8 = { class: "program-list-content-title desktop" }
const _hoisted_9 = { class: "program-list-content" }
const _hoisted_10 = { class: "program-list-content-header" }
const _hoisted_11 = { class: "program-list-content-items" }
const _hoisted_12 = { class: "program-list-item" }
const _hoisted_13 = { class: "program-list-item-title" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "program-list-item-infos"
}
const _hoisted_16 = { class: "program-list-content-header" }
const _hoisted_17 = { class: "program-list-content-items" }
const _hoisted_18 = { class: "program-list-item" }
const _hoisted_19 = { class: "program-list-item-title" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 0,
  class: "program-list-item-infos"
}
const _hoisted_22 = {
  key: 1,
  class: "program-list-item-infos"
}
const _hoisted_23 = {
  key: 2,
  class: "program-list-item-infos"
}
const _hoisted_24 = { class: "page2Col__aside" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "program-list-content-header desktop" }
const _hoisted_27 = { class: "program-list-content program-list-content--rounded" }
const _hoisted_28 = {
  key: 0,
  class: "program-list-content-header"
}
const _hoisted_29 = { class: "program-list-content-items program-list-content-items--geolocation" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 2,
  class: "program-list-item-wrapper"
}
const _hoisted_32 = { class: "program-list-item" }
const _hoisted_33 = { class: "program-list-item-title" }
const _hoisted_34 = {
  key: 0,
  class: "program-list-item-infos"
}
const _hoisted_35 = {
  key: 3,
  class: "program-list-item-title geolocation-warning"
}
const _hoisted_36 = { class: "BottomPopin__text" }
const _hoisted_37 = { class: "BottomPopin__title" }
const _hoisted_38 = { class: "BottomPopin__subtitle" }
const _hoisted_39 = { class: "BottomPopin__buttons" }
const _hoisted_40 = { class: "BottomPopin__text" }
const _hoisted_41 = { class: "BottomPopin__title" }
const _hoisted_42 = { class: "BottomPopin__subtitle" }
const _hoisted_43 = { class: "BottomPopin__buttons" }
const _hoisted_44 = {
  key: 3,
  class: "mobilePopin"
}

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import MyVIcon from '@/components/ui/atoms/MyVIcon/MyVIcon.vue';

import logo from "@/assets/images/logo_beta.svg?url";

import {
  AppliancesModule,
  AuthModule,
  DiagnosticModule,
  GeolocationModule,
  HeatingModule,
  ProgramModule,
  RouterModule,
  SiteModule
} from "@/store";
import {TProgram} from "@/services/Program/interfaces";
import ERoutes from "@/router/routes";
import {EQuickSettingsName, TQuickSettings} from "@/services/Heating/interfaces";

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";

import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {g_appTitle} from "@/helpers/constants/global";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {Checkbox, notification} from "ant-design-vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import Cookie from "@/helpers/cookie";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {refDebounced, useWindowSize} from "@vueuse/core";
import MyVGeolocationPopin from "@/components/domains/Geolocation/MyVGeolocationPopin/MyVGeolocationPopin.vue";
import {isWebViewMobileApp} from "@/helpers/domains/device";
import {TGeolocationStatus} from "@/services/Geolocation/interfaces";
import {TEditQuickSettingDatas} from "@/components/domains/Heating/quicksettings/MyVEditQuickSetting/interfaces";
import MyVEditQuickSetting
  from "@/components/domains/Heating/quicksettings/MyVEditQuickSetting/MyVEditQuickSetting.vue";
import MyVCustomDuration from "@/components/domains/Heating/quicksettings/MyVCustomDuration/MyVCustomDuration.vue";
import MyVProgReminder from "@/components/domains/Heating/programs/MyVProgReminder/MyVProgReminder.vue";
import MyVGeolocationPermissionsPopin
  from "@/components/domains/Geolocation/MyVGeolocationPermissionsPopin/MyVGeolocationPermissionsPopin.vue";
import {TgeolocWarningPopinMessages} from "@/views/domains/Heating/Program/Programlist/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {useResponsive} from "@/composables/useResponsive";
import MyVTemperatureControlModal
  from "@/components/domains/Heating/programs/MyVTemperatureControlModal/MyVTemperatureControlModal.vue";
import {useCookies} from "@vueuse/integrations/useCookies";
import {EButtonSize} from "@/components/ui/atoms/MyVButton/interfaces";
import {useDate} from "@/helpers/dates/date-utils";
import {useCookieNames} from "@/composables/useCookieNames";
import router from "@/router";
import { openAppRatingModal, openAppRatingModalWithTimeout } from "@/helpers/domains/mobileApps/rating";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramlistView',
  setup(__props) {

const isFromProgramPage = ref(false);
const currentQuickSetting = ref<TQuickSettings | null>(null);
const showResetProgModal = ref(false)
const showProgReminder = ref(false);
const dontShowProgReminderAgain = ref(false);
const isShowMorePrograms = ref(false)
const isGeolocationPopinWarnOpen = ref(false)
const quickSettingsToEnabled = ref<TQuickSettings | null>(null);
const programToEnabled = ref<TProgram | null>(null);
const isOpenPopinDuration = ref(false);
const isTemperatureControlModalOpen = ref(false);
const isGeolocationShow = ref(false);
const showGeolocationFix = ref(false);
const showGeolocationDisclaimer = ref(false);
const pausedProgram = ref<TProgram>()
const debouncedPausedProgram = refDebounced<TProgram | undefined>(pausedProgram, 1000)
const pausedQuicksetting = ref<TQuickSettings>()
const debouncedPausedQuicksetting = refDebounced<TQuickSettings | undefined>(pausedQuicksetting, 1000)

const geolocWarningPopinMessages: TgeolocWarningPopinMessages = reactive({
  title: undefined,
  subtitle: undefined
})

const {width: windowWidth} = useWindowSize()
const {isMobile} = useResponsive()
const {t} = useI18n()
const route = useRoute()
const {temperatureControlReminderCookie, progReminderCookie} = useCookieNames()
const cookies = useCookies([temperatureControlReminderCookie.value])

const isSiteCountryFrance = computed<boolean>(() => SiteModule.isSiteCountryFrance)

const linkCreateProgram = computed(() => {
  return `${ERoutes.PROGRAM}/${ERoutes.ADDON_CREATE}`;
})

const activeProgram = computed(() => {
  return ProgramModule.programmingState
})

const programs = computed(() => {
  if (isShowMorePrograms.value)
    return ProgramModule.programs ?? [];

  if (!ProgramModule.programs?.slice(0, 3).some(prog => prog.enabled)) {
    return ProgramModule.programs?.sort((a, b) => Number(b.enabled) - Number(a.enabled)).slice(0, 3) ?? [];
  }

  return ProgramModule.programs?.slice(0, 3) ?? [];
})

const isGeolocationActivated = computed(() => {
  return GeolocationModule.status?.activatedByUser
})

const isPhoneLocationServiceEnabled = computed(() => {
  return GeolocationModule.phoneLocationServiceEnabled
})

const quickSettings = computed(() => {
  return HeatingModule.quickSettings;
})

const programEditRoute = computed(() => {
  return (id: TProgram['id']) => `${ERoutes.PROGRAM}/${id}/${ERoutes.ADDON_PLANNING}`;
})

const isOpenPopinEditQuickSetting = computed(() => {
  return currentQuickSetting.value !== null;
})

const appliances = computed(() => {
  return AppliancesModule.appliances;
})

const isFlutterApp = computed(() => {
  return isWebViewMobileApp(window)
})

const isCurrentPhoneInTrackedPhones = computed(() => {
  return GeolocationModule.isCurrentPhoneTracked
})

const permissionsNeeded = computed(() => {
  return GeolocationModule.isPermissionNeeded
})

const isTablet = computed(() => {
  return windowWidth.value <= 900
})

const hasTemperatureControlCookie = computed(() => {
  return cookies.get(temperatureControlReminderCookie.value)
})

const status = computed(() => {
  return GeolocationModule.status
})

const phones = computed(() => {
  return GeolocationModule.phones ?? []
})

const buildPausedQuicksettingMessage = computed(() => {
  if (debouncedPausedQuicksetting.value?.id === HeatingModule.getPausedQuicksetting?.id) {
    return t('global.paused')
  }

  return undefined
})

const buildPausedProgramMessage = computed(() => {
  if (debouncedPausedProgram.value?.id === ProgramModule.getPausedProgram?.id) {
    if (activeProgram.value?.untilFurtherNotice && !activeProgram.value?.geolocCurrentlyOn) {
      return `${t('programList.programs.pausedBy')} : ${t(activeProgram.value?.programName)}`
    }

    if (activeProgram.value && useDate(activeProgram.value.endDate).isValid()) {
      return `${t('global.resumeOn')} : ${useDate(activeProgram.value.endDate).calendar()}`
    }

    if (activeProgram.value?.geolocCurrentlyOn) {
      return t('geolocation.pausedBy')
    }

    return t('global.paused')

  }

  return undefined
})

const openResetProgModal = () => {
  showResetProgModal.value = true
  pageScroll(false)
}

const closeResetProgModal = () => {
  showResetProgModal.value = false
  pageScroll(true)
}

const resetProgram = () => {
  const promises: Promise<any>[] = [ProgramModule.resetProgram()]

  if (GeolocationModule.status?.activatedByUser) {
    const statusPayload: TGeolocationStatus = {
      radius: GeolocationModule.status.radius,
      activatedByUser: !status.value?.activatedByUser,
    }
    promises.push(GeolocationModule.putStatus(statusPayload))
  }

  Promise.all(promises).then(() => {
    notification.success({
      message: t('program.reset.success.title'),
      description: t('program.reset.success.desc'),
      duration: 3
    })
    closeResetProgModal()
    initData(true)
  })
}

const handleSetPeriod = async ({id, endDate, untilFurtherNotice}: any) => {
  await HeatingModule.updateQuickSetting({
    id,
    body: {
      untilFurtherNotice,
      modeEndDate: endDate,
    }
  })
      .then(() => {
        HeatingModule.startQuickSettings(id);
      }).finally(() => {
        if (quickSettingsToEnabled.value && HeatingModule.hasActiveQuicksettingTemperatureControl(quickSettingsToEnabled.value.id) && !hasTemperatureControlCookie.value) {
          isTemperatureControlModalOpen.value = true;
        } else {
          openAppRatingModalWithTimeout()
          closePopinDuration();
        }
      })
}

const handleAutoGeolocationDisabling = () => {
  const statusPayload: TGeolocationStatus = {
    radius: GeolocationModule.status?.radius,
    activatedByUser: false,
  }

  if (quickSettingsToEnabled.value && !activeProgram.value?.geolocCurrentlyOn) {
    HeatingModule.stopQuickSettings()
    isGeolocationPopinWarnOpen.value = false
    isOpenPopinDuration.value = true;
  } else if (quickSettingsToEnabled.value && !quickSettingsToEnabled.value.enabled && activeProgram.value?.geolocCurrentlyOn) {
    isGeolocationPopinWarnOpen.value = false
    isOpenPopinDuration.value = true;
    GeolocationModule.putStatus(statusPayload)
  } else if (programToEnabled.value) {
    ProgramModule.updateProgram({
      id: programToEnabled.value.id,
      body: {
        enabled: true
      }
    })
        .then(() => {
          GeolocationModule.putStatus(statusPayload)
        }).finally(() => {
      isGeolocationPopinWarnOpen.value = false
    })
  } else if (activeProgram.value?.geolocCurrentlyOn) {
    HeatingModule.stopQuickSettings()
    isGeolocationPopinWarnOpen.value = false
  }
}

const toggleProgram = (program: TProgram) => {
  programToEnabled.value = program;
  quickSettingsToEnabled.value = null;

  const enabled = !program.enabled;

  if (activeProgram.value?.geolocCurrentlyOn && !program.enabled) {
    programToEnabled.value = program
    isGeolocationPopinWarnOpen.value = true
    geolocWarningPopinMessages.title = t('programList.geolocation.enabled.warn.program.title')
    geolocWarningPopinMessages.subtitle = t('programList.geolocation.disabled.warn.subtitle')
  } else {
    ProgramModule.updateProgram({
      id: program.id,
      body: {
        enabled
      }
    }).finally(() => {
      if (programToEnabled.value && ProgramModule.hasActiveProgramTemperatureControl && !hasTemperatureControlCookie.value) {
        isTemperatureControlModalOpen.value = true;
      } else if (enabled) {
        openProgReminder();
      }
    })
  }
}

const quickSettingsSelect = (quickSettings: TQuickSettings) => {
  quickSettingsToEnabled.value = quickSettings;
  programToEnabled.value = null;

  if (activeProgram.value?.geolocCurrentlyOn && !quickSettings.enabled) {
    geolocWarningPopinMessages.title = t('programList.geolocation.enabled.warn.quickMode.title')
    geolocWarningPopinMessages.subtitle = t('programList.geolocation.disabled.warn.subtitle')
    isGeolocationPopinWarnOpen.value = true
  } else if (activeProgram.value?.geolocCurrentlyOn && quickSettings.enabled) {
    geolocWarningPopinMessages.title = t('programList.geolocation.disabled.warn.shortLeave.title')
    geolocWarningPopinMessages.subtitle = undefined
    isGeolocationPopinWarnOpen.value = true
  } else if (quickSettings.enabled) {
    HeatingModule.stopQuickSettings()
  } else {
    isOpenPopinDuration.value = true;
  }
}

const openProgReminder = () => {
  const cookie = Cookie.get(progReminderCookie.value);
  if (!cookie) {
    showProgReminder.value = true;
    pageScroll(false);
    return
  }
  openAppRatingModalWithTimeout()
}

const toggleGeolocation = async () => {
  if (!isGeolocationActivated.value)
    showGeolocationDisclaimer.value = true

  const statusPayload: TGeolocationStatus = {
    radius: GeolocationModule.status?.radius,
    activatedByUser: !status.value?.activatedByUser,
  }

  return GeolocationModule.putStatus(statusPayload).then(() => {
    GeolocationModule.refreshGeofences()
    HeatingModule.getQuickSettings(true)
    ProgramModule.getPrograms(true)
    ProgramModule.getProgrammingState()
  })
}

const closeProgReminder = () => {
  showProgReminder.value = false;
  pageScroll(true);
  if (dontShowProgReminderAgain.value) {
    Cookie.set(progReminderCookie.value, '1');
  }
  openAppRatingModalWithTimeout()
}

const initData = (forceRequest = false) => {
  ProgramModule.getPrograms(forceRequest);
  AppliancesModule.getAppliances(forceRequest);
  HeatingModule.getQuickSettings(forceRequest)
      .then(quickSettings => {
        if (route?.query?.quickSettings) {
          const quickSettingId = parseInt(route.query.quickSettings as string);
          const quickSetting = quickSettings!.find(({id}) => quickSettingId === id);

          if (quickSetting) {
            currentQuickSetting.value = quickSetting;
          }
        }
      });
}

const openPopinEditQuickSetting = (quickSetting: TQuickSettings) => {
  openAppRatingModal()
  isFromProgramPage.value = true;
  currentQuickSetting.value = quickSetting;
  pageScroll(false);
}

const closePopinEditQuickSetting = () => {
  pageScroll(true);
  if (isFromProgramPage.value || !RouterModule.hasHistory) {
    isFromProgramPage.value = false;
    currentQuickSetting.value = null;
  } else {
    RouterModule.goBack();
  }
}

const submitEditQuickSetting = (formDatas: TEditQuickSettingDatas) => {
  HeatingModule.updateQuickSetting({
    id: currentQuickSetting.value!.id,
    body: {
      untilFurtherNotice: formDatas.untilFurtherNotice,
      modeEndDate: formDatas.endDate,
      appliancesSettings: formDatas.appliances.map(appliance => ({
        idAppliance: appliance.idAppliance,
        mode: appliance.mode,
        isOn: appliance.isOn,
        temperatureTarget: appliance.temperatureTarget
      }))
    }
  })
      .then(quickSetting => quickSetting!.enabled ? AppliancesModule.getAppliances(true) : Promise.resolve())
}

const handleGeolocationPopinClose = () => {
  Promise.all([
    ProgramModule.getProgrammingState(),
    ProgramModule.getPrograms(true),
    HeatingModule.getQuickSettings(true)
  ]).then(() => GeolocationModule.getStatus())
      .catch((e) => console.error(e))
      .finally(() => isGeolocationShow.value = false)
}

const closePopinDuration = () => {
  isOpenPopinDuration.value = false;
  pageScroll(true);
}

const checkToOpenQuickSettings = () => {
  const quickSettingsId = route.query.quickSettingsId as string;
  if (!quickSettingsId) return;
  const quickSetting = HeatingModule.quickSettings!.find(({id}) => id == parseInt(quickSettingsId));

  openPopinEditQuickSetting(quickSetting!);
}

function goToProgramEditRoute(programId: number) {
  openAppRatingModal()
  router.push(programEditRoute.value(programId))
}

onMounted(() => {
  ProgramModule.getProgrammingState()
  HeatingModule.getQuickSettings(true)
})

onBeforeMount(async () => {
  await Promise.all([DiagnosticModule.getDiagnostics({siteId: getCurrentSiteIdFromCookie(AuthModule.user)}), GeolocationModule.retrievePhoneDatas(), GeolocationModule.getDeviceLocationState(), GeolocationModule.getVersion()])

  initData();
  checkToOpenQuickSettings();
})

watch(isOpenPopinEditQuickSetting, (newValue) => {
  if (newValue) pageScroll(false);
  else pageScroll(true);
})

watch(() => ProgramModule.getPausedProgram, (newValue) => {
  pausedProgram.value = newValue
}, {immediate: true})

watch(() => HeatingModule.getPausedQuicksetting, (newValue) => {
  pausedQuicksetting.value = newValue
}, {immediate: true})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(MyVPageMain, {
        homeLink: _unref(ERoutes).HOME,
        logo: _unref(logo),
        title: _unref(g_appTitle),
        hasProgramListBar: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(MyVHeaderMainPage, { title: "programList.page.title" }, {
              HeaderMainPage__breadcrumb: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _unref(ERoutes).HEATING,
                  class: "HeaderMainPage__breadcrumb-link"
                }, {
                  default: _withCtx(() => [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "HeaderMainPage__breadcrumb-link-icon" }, [
                      _createElementVNode("svg", {
                        class: "icon icon-arrow-left",
                        "aria-hidden": "true"
                      }, [
                        _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_3, " < " + _toDisplayString(_ctx.$t("programList.breadcrumb.backLink")), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }),
            (!isPhoneLocationServiceEnabled.value && isFlutterApp.value && isGeolocationActivated.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('programList.menu.geolocation.deviceLocation.disabled')), 1))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(['mobile','program-list-current-mode', {'program-list-current-mode--active': activeProgram.value?.programName }])
                }, _toDisplayString(_ctx.$t(activeProgram.value?.programName ? activeProgram.value?.programName : 'programList.menu.noActiveControl')), 3))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.$t('programList.title')) + " ", 1),
                  _createVNode(_component_router_link, {
                    class: "desktop",
                    to: linkCreateProgram.value
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyVButton, {
                        size: _unref(EButtonSize).Small
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('programList.createProgram')), 1)
                        ]),
                        _: 1
                      }, 8, ["size"])
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(['desktop','program-list-current-mode', {'program-list-current-mode--active': activeProgram.value?.programName }])
                }, _toDisplayString(_ctx.$t(activeProgram.value?.programName ? activeProgram.value?.programName : 'programList.menu.noActiveControl')), 3),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('programList.menu.programs')), 1),
                      (isTablet.value)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: linkCreateProgram.value
                          }, {
                            default: _withCtx(() => [
                              _createVNode(MyVButton, {
                                size: _unref(EButtonSize).Small
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('global.new')), 1)
                                ]),
                                _: 1
                              }, 8, ["size"])
                            ]),
                            _: 1
                          }, 8, ["to"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(programs.value, (program) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `program-${program.id}`,
                          class: "program-list-item-wrapper"
                        }, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t(program?.name)), 1),
                            _createVNode(MyVAntdswitch, {
                              value: program.enabled,
                              onChange: ($event: any) => (toggleProgram(program)),
                              hasCheckStyle: true,
                              "is-debounced": "",
                              "is-paused": _unref(debouncedPausedProgram)?.id === program.id,
                              "checked-children-icon": _unref(debouncedPausedProgram)?.id === program.id ? 'pause2' : undefined
                            }, null, 8, ["value", "onChange", "is-paused", "checked-children-icon"]),
                            _createElementVNode("div", {
                              class: "program-list-item-edit",
                              onClick: ($event: any) => (goToProgramEditRoute(program.id))
                            }, [
                              _createVNode(MyVIcon, { name: "pen" })
                            ], 8, _hoisted_14)
                          ]),
                          (_unref(debouncedPausedProgram)?.id === program.id && buildPausedProgramMessage.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createVNode(MyVIcon, { name: "play3" }),
                                _createTextVNode(" " + _toDisplayString(buildPausedProgramMessage.value), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_unref(ProgramModule).programs?.length > 3)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "program-list-show-more",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowMorePrograms.value = !isShowMorePrograms.value))
                          }, _toDisplayString(isShowMorePrograms.value ? _ctx.$t('programlist.showLessPrograms') : _ctx.$t('programlist.showMorePrograms')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('programList.menu.quick_modes')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quickSettings.value, (quicksetting) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `quicksetting-${quicksetting.id}`,
                          class: "program-list-item-wrapper"
                        }, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t(quicksetting?.name)), 1),
                            _createVNode(MyVAntdswitch, {
                              value: quicksetting.enabled && (quicksetting.untilFurtherNotice || _unref(useDate)(quicksetting.modeEndDate).isAfter(new Date())),
                              onChange: ($event: any) => (quickSettingsSelect(quicksetting)),
                              hasCheckStyle: true,
                              "is-debounced": "",
                              "is-paused": _unref(debouncedPausedQuicksetting)?.id === quicksetting.id,
                              "checked-children-icon": _unref(debouncedPausedQuicksetting)?.id === quicksetting.id ? 'pause2' : undefined
                            }, null, 8, ["value", "onChange", "is-paused", "checked-children-icon"]),
                            _createElementVNode("div", {
                              class: "program-list-item-edit",
                              onClick: ($event: any) => (openPopinEditQuickSetting(quicksetting))
                            }, [
                              _createVNode(MyVIcon, { name: "pen" })
                            ], 8, _hoisted_20)
                          ]),
                          (_unref(debouncedPausedQuicksetting)?.id === quicksetting.id && buildPausedQuicksettingMessage.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _createVNode(MyVIcon, { name: "play3" }),
                                _createTextVNode(" " + _toDisplayString(buildPausedQuicksettingMessage.value), 1)
                              ]))
                            : (quicksetting.name === _unref(EQuickSettingsName).ShortAbsence && activeProgram.value?.programName === _unref(EQuickSettingsName).ShortAbsence  && activeProgram.value?.geolocCurrentlyOn)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                  _createVNode(MyVIcon, { name: "pin" }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('programList.geolocation.activatedByGeolocation')), 1)
                                ]))
                              : (quicksetting.enabled && (quicksetting.untilFurtherNotice || _unref(useDate)(quicksetting.modeEndDate).isAfter(new Date())) && activeProgram.value?.id === quicksetting.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                    _createVNode(MyVIcon, { name: "play3" }),
                                    _createTextVNode(" " + _toDisplayString(`${_ctx.$t('global.endOn')} : ${activeProgram.value?.endDate ? _unref(useDate)(activeProgram.value?.endDate).calendar() : _ctx.$t('heating.duration.untilFurtherNotice')}`), 1)
                                  ]))
                                : _createCommentVNode("", true),
                          (isOpenPopinDuration.value)
                            ? (_openBlock(), _createBlock(MyVCustomDuration, {
                                key: 3,
                                onClose: _cache[1] || (_cache[1] = ($event: any) => (isOpenPopinDuration.value = false)),
                                quickSettingsToEnabled: quickSettingsToEnabled.value!,
                                "is-emit-mode": true,
                                onSetPeriod: handleSetPeriod,
                                onOpenReminder: openProgReminder
                              }, null, 8, ["quickSettingsToEnabled"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                (_unref(GeolocationModule).isGeolocReady && isSiteCountryFrance.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", null, [
                          _createVNode(MyVIcon, { name: "pin" }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('programList.menu.geolocation')), 1)
                        ]),
                        (phones.value.length)
                          ? (_openBlock(), _createBlock(MyVButton, {
                              key: 0,
                              size: _unref(EButtonSize).Small,
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (isGeolocationShow.value = true))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('global.configure')), 1)
                              ]),
                              _: 1
                            }, 8, ["size"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", null, [
                          (isTablet.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                _createElementVNode("div", null, [
                                  _createVNode(MyVIcon, { name: "pin" }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('programList.menu.geolocation')), 1)
                                ]),
                                (phones.value.length)
                                  ? (_openBlock(), _createBlock(MyVButton, {
                                      key: 0,
                                      size: _unref(EButtonSize).Small,
                                      onClick: _cache[3] || (_cache[3] = ($event: any) => (isGeolocationShow.value = true))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('global.configure')), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["size"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_29, [
                            (!phones.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.$t('programList.geolocation.enabled.description')), 1))
                              : _createCommentVNode("", true),
                            (!phones.value.length)
                              ? (_openBlock(), _createBlock(MyVButton, {
                                  key: 1,
                                  size: _unref(EButtonSize).Small,
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (isGeolocationShow.value = true))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('global.configure')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["size"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                  _createElementVNode("div", _hoisted_32, [
                                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('programList.menu.geolocation.activate')), 1),
                                    _createVNode(MyVAntdswitch, {
                                      value: isGeolocationActivated.value,
                                      onChange: toggleGeolocation,
                                      class: "program-list-item-switch",
                                      hasCheckStyle: true
                                    }, null, 8, ["value"])
                                  ]),
                                  (!isPhoneLocationServiceEnabled.value && isFlutterApp.value && isGeolocationActivated.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                        _createVNode(MyVIcon, { name: "exclamation-circle" }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('programList.menu.geolocation.deviceLocation.error')), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ])),
                            (isFlutterApp.value && isCurrentPhoneInTrackedPhones.value && permissionsNeeded.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                  _createElementVNode("div", null, [
                                    _createVNode(MyVIcon, { name: "exclamation-circle" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('programList.menu.geolocation.notTracked')), 1)
                                  ]),
                                  _createVNode(MyVButton, {
                                    size: _unref(EButtonSize).Small,
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (showGeolocationFix.value = true))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('global.fix')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["size"])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  class: "program-list-content-reset desktop",
                  onClick: openResetProgModal
                }, _toDisplayString(_ctx.$t('programList.resetProgram.long')), 1),
                _createElementVNode("a", {
                  class: "programListBlock__resetProgram mobile",
                  onClick: openResetProgModal
                }, _toDisplayString(_ctx.$t('programList.resetProgram.short')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["homeLink", "logo", "title"]),
      (showGeolocationFix.value)
        ? (_openBlock(), _createBlock(MyVGeolocationPermissionsPopin, {
            key: 0,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (showGeolocationFix.value = false))
          }))
        : _createCommentVNode("", true),
      (isGeolocationPopinWarnOpen.value)
        ? (_openBlock(), _createBlock(MyVBottomPopin, {
            key: 1,
            onClose: _cache[8] || (_cache[8] = ($event: any) => (Object.assign(geolocWarningPopinMessages, {}))),
            onCancel: _cache[9] || (_cache[9] = ($event: any) => (isGeolocationPopinWarnOpen.value = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _cache[16] || (_cache[16] = _createElementVNode("svg", {
                  class: "icon icon-big-colored-delete-forever",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                ], -1)),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, _toDisplayString(geolocWarningPopinMessages.title), 1),
                  _createElementVNode("div", _hoisted_38, _toDisplayString(geolocWarningPopinMessages.subtitle), 1)
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(MyVButton, {
                    class: "BottomPopin__confirm",
                    onClick: handleAutoGeolocationDisabling
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(MyVButton, {
                    class: "BottomPopin__cancel",
                    primary: false,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (isGeolocationPopinWarnOpen.value = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (showResetProgModal.value)
        ? (_openBlock(), _createBlock(MyVBottomPopin, {
            key: 2,
            onCancel: closeResetProgModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _cache[17] || (_cache[17] = _createElementVNode("svg", {
                  class: "icon icon-big-colored-delete-forever",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                ], -1)),
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('program.popin.reset.title')), 1),
                  _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t('program.popin.reset.subtitle')), 1)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createVNode(MyVButton, {
                    class: "BottomPopin__confirm",
                    onClick: resetProgram
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(MyVButton, {
                    class: "BottomPopin__cancel",
                    primary: false,
                    onClick: closeResetProgModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (isOpenPopinEditQuickSetting.value && currentQuickSetting.value !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
            _createVNode(MyVPageWithReturn, {
              class: "editQuickSettingPopin",
              title: _ctx.$t('programList.popin.edit_quick_settings.title', { quickSettings: _ctx.$t(currentQuickSetting.value.name) }),
              returnAction: closePopinEditQuickSetting,
              type: "popin"
            }, {
              default: _withCtx(() => [
                _createVNode(MyVEditQuickSetting, {
                  untilFurtherNotice: currentQuickSetting.value.untilFurtherNotice,
                  endDate: currentQuickSetting.value.modeEndDate,
                  appliancesSettings: currentQuickSetting.value.appliancesSettings,
                  returnAction: closePopinEditQuickSetting,
                  onSubmit: submitEditQuickSetting,
                  appliances: appliances.value,
                  currentQuickSetting: currentQuickSetting.value!
                }, null, 8, ["untilFurtherNotice", "endDate", "appliancesSettings", "appliances", "currentQuickSetting"])
              ]),
              _: 1
            }, 8, ["title"])
          ]))
        : _createCommentVNode("", true),
      (showProgReminder.value)
        ? (_openBlock(), _createBlock(MyVPageWithReturn, {
            key: 4,
            class: "ProgReminderPopin",
            title: _ctx.$t('prog_reminder.title'),
            type: "popin",
            "return-action": closeProgReminder
          }, {
            default: _withCtx(() => [
              _createVNode(MyVProgReminder),
              _createVNode(MyVButton, {
                onClick: closeProgReminder,
                class: "closeButton"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.ok')), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(Checkbox), {
                class: "dontAskAgain",
                checked: dontShowProgReminderAgain.value,
                "onUpdate:checked": _cache[10] || (_cache[10] = ($event: any) => ((dontShowProgReminderAgain).value = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.dont_ask_again')), 1)
                ]),
                _: 1
              }, 8, ["checked"])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (isGeolocationShow.value)
        ? (_openBlock(), _createBlock(MyVGeolocationPopin, {
            key: 5,
            onClose: handleGeolocationPopinClose
          }))
        : _createCommentVNode("", true),
      (showGeolocationDisclaimer.value)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? MyVBottomPopin : MyVPageWithReturn), {
            key: 6,
            onClose: _cache[12] || (_cache[12] = ($event: any) => (showGeolocationDisclaimer.value = false)),
            title: _ctx.$t('geolocation.disclaimer.title'),
            type: "popin",
            "return-action": () => showGeolocationDisclaimer.value = false,
            onCancel: _cache[13] || (_cache[13] = ($event: any) => (showGeolocationDisclaimer.value = false))
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('geolocation.disclaimer.title')), 1)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t('geolocation.disclaimer.description')) + " ", 1),
              _createVNode(MyVButton, {
                class: "BottomPopin__buttons",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (showGeolocationDisclaimer.value=false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.ok')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 40, ["title", "return-action"]))
        : _createCommentVNode("", true)
    ]),
    (isTemperatureControlModalOpen.value)
      ? (_openBlock(), _createBlock(MyVTemperatureControlModal, {
          key: 0,
          onClose: _cache[14] || (_cache[14] = ($event: any) => (isTemperatureControlModalOpen.value= false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})