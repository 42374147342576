import {RouteLocationRaw} from "vue-router";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import router from "@/router";

import {EModules} from "../modules";

import ERoutes from "@/router/routes";

@Module({name: EModules.ROUTER})
class Router extends VuexModule {

    private _counter = 0;
    private _lastPath: ERoutes | null = null;

    /**
     * Getters
     */

    get hasHistory(): boolean {
        return this._counter > 0;
    }

    get lastPath() {
        return this._lastPath;
    }

    /**
     * Mutations
     */

    @Mutation
    public _incrementCounter(lastPath: ERoutes): void {
        this._counter = this._counter + 1;
        this._lastPath = lastPath;
    }

    public _decrementCounter(): void {
        this._counter = this._counter - 1;
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public incrementCounter(lastPath: ERoutes): void {
        this.context.commit("_incrementCounter", lastPath);
    }

    @Action({rawError: true})
    public goBack(fallback?: RouteLocationRaw): void {
        if (this.hasHistory) {
            router.back();
            this.context.commit("_incrementCounter");
        } else if (fallback) {
            router.push(fallback);
        } else {
            throw new Error('Router error: Impossible to go back !');
        }
    }
}

export default Router;
