export type TManageChoices = {
  categoryKey: string;
  subcategories: TManageChoicesSubcategories[];
  htmlId: string;
}

type TManageChoicesSubcategories = {
  nameKey: string;
  enabled: boolean;
  descriptionKey: string;
}

export enum EManageChoicesSections {
  AlertsAndNotifications = "consent.notif-and-alert.category",
  Consumption = "consent.consumption.category",
  DatasCollect = "enedis.consent.transmission.category",
  Informations = "consent.information.category"
}