import { useDate } from "@/helpers/dates/date-utils"
import { isWebViewMobileApp } from "@/helpers/domains/device"
import { getVoltalisVersion } from "@/helpers/domains/mobileApps/flutter-connectors"
import { EFlutterConnectorRequestType, EVoltalisConnectorRating } from "@/helpers/domains/mobileApps/interfaces"
import { TNextDisplayDate } from "@/services/Rating/interfaces"
import { AuthModule, RatingModule } from "@/store"

//used only in certain conditions (determined by the product team)
export const DELAY_TO_SHOW_APP_RATING = 2000

function isAppRatingDisplayable(storedNextDisplayDate: TNextDisplayDate | undefined): boolean {
  const today = useDate()
  return useDate(storedNextDisplayDate).isBefore(today)
}

export async function openAppRatingModal(): Promise<void> {
  if (!AuthModule.isBetaTester) return;

  const nextDisplayDate : TNextDisplayDate | undefined = await RatingModule.getNextDisplayDate();

  const isDisplayable = isAppRatingDisplayable(nextDisplayDate);

  if (isWebViewMobileApp(window) && !AuthModule.user.firstConnection && isDisplayable) {
    try {
      await window.flutter_inappwebview.callHandler(EFlutterConnectorRequestType.voltalis, {
        type: EVoltalisConnectorRating.openAppRatingModal,
      });
      await RatingModule.updateNextDisplayDate()
    } catch (e) {
      console.error(e);
    }
  }
}

export function openAppRatingModalWithTimeout() {
  setTimeout(openAppRatingModal, DELAY_TO_SHOW_APP_RATING)
}